import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "./index.css"

const Authors = [
  "Maria Anwander",
  "Heike Baranowsky",
  "Gabriel Blétel",
  "Dachroth + Jeschonnek",
  "Olafur Eliasson",
  "Tom Früchtl",
  "Miriam Jonas",
  "Lajos Kassák",
  "Caroline Kryzecki",
  "Florian Lechner",
  "Inge Mahn",
  "Manfred Mohr",
  "Vera Molnár",
  "Pätzug / Hertweck",
  "Sophia Pompéry",
  "Sinta Werner",
  "Lena von Goedeke",
]
const AuthorList = () => (
  <ul className="authorList">
    {Authors.map((a, idx) => (
      <li key={idx}>
        <strong>{a}</strong>
      </li>
    ))}
  </ul>
)

const IndexPage = () => (
  <Layout>
    <SEO title="Hausstellen" />
    <h1>Hausstellen</h1>
    <h3>27.- 29. September 2019</h3>
    <div
      style={{
        maxWidth: "450px",
      }}
    >
      <Image />
    </div>

    <AuthorList />

    <p>
      Physikalisch ist eine Fata Morgana als Luftspiegelung leicht erklärbar.
      Metaphorisch steht sie für Sinnestäuschung und bildet das Thema des
      diesjährigen Ortstermins, an dem rund 80 Ateliers und Ausstellungsräume
      Berlin-Moabits teilnehmen. „Die Fata Morgana bringt optische Zerrbilder
      der Realität oder gar Halluzinationen hervor. Als Vision unerreichbarer
      Orte scheint sie mit der Sehnsucht nach einer anderen Wirklichkeit
      verbunden zu sein, aber auch mit – womöglich nicht einlösbaren –
      Versprechungen. Das optische Flimmern könnte aber durchaus auch Visionen
      hervorbringen, die uns kein Trugbild vorgaukeln, sondern utopisches
      Potential in sich bergen“, so schreiben die Organisatoren des Moabiter
      Kunstfestivals.
    </p>
    <p>
      Im Rahmen dieses Programmes wird die Künstlerin Sophia Pompéry ihr „Haus
      stellen“, um feinsinnige, doppelbödige Arbeiten zu zeigen, die ihren
      Ausgangspunkt in naturwissenschaftlichen Phänomenen finden. Die{" "}
      {Authors.length} teilnehmenden Künstler*Innen beschäftigen sich in ihren
      Malereien, Installationen, Papierarbeiten, Objekten und Fotographien
      eingehend mit Wahrnehmungsfragen. Subtil und scharfsinnig schlagen sie in
      ihren minimalistischen und schwerelos wirkenden Arbeiten eine Brücke
      zwischen Kunst, Philosophie und Physik.
    </p>
    <p>
      Zusammen mit Alltagsgegenständen einer Wohnung verdichtet sich die
      Ausstellung zu einer Gesamtinszenierung: zum einen geht es darum die
      einzelnen Werke für sich gelten zu lassen, zum anderen sie in
      spannungsvolle Dialoge untereinander zu bringen, sie nach einer bestimmten
      Ordnung zu sortieren oder sie mit Alltagsgegenständen zu kombinieren.
    </p>
    <p>
      Das Zusammenspiel von Luft und Licht, das zu einer Fata Morgana führt,
      verleitet uns zu einem Perspektivenwechsel, schafft Bewegungen und
      Spiegelungen, wie auch Brechungen in Raum und Zeit. Ist unsere Sehnsucht
      nach dem Authentischen eine Falle? Und wie wirkt sich das auf unsere
      Wahrnehmung und unseren Erkenntnisgewinn aus? Die ausgestellten Werke sind
      eigenständige Räume die nebeneinander bestehen und sich im Laufe der
      Ausstellung zueinander in Beziehung setzen. „Hausstellen“ täuscht eine
      Wohnsituation vor, bleibt aber der flüchtige Moment einer trügerischen
      Privatheit.
    </p>
    <p>
      <b>Pompéry Privat</b>
      <br />
      Bredowstr. 35a
      <br />
      10551 Berlin-Moabit
      <br />
      <a href="mailto:post@sophiapompery.de">post@sophiapompery.de</a>
    </p>
    <p>
      <b>Besuchszeiten</b>
      <br />
      Freitag, den 27.9, Vernissage 18-22h
      <br />
      Samstag, den 28.9, 15-18h
      <br />
      Sonntag, den 29.9, 15-20h
    </p>
    <p>Führungen werden zur halben Stunde (15:30, 16:30, usw.) angeboten.</p>
    <p>
      Weitere Orte im Rahmen des Kunstfestivals Moabit:
      <a href="https://ortstermin.kunstverein-tiergarten.de">
        https://ortstermin.kunstverein-tiergarten.de
      </a>
    </p>
  </Layout>
)

export default IndexPage
