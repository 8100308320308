/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  return (
    <>
      <div
        style={{
          margin: `3.12rem auto 0`,
          padding: "0 0.5rem",
          maxWidth: 960,
        }}
      >
        <main>{children}</main>
        <footer>
          <br />
          <small>
            © {new Date().getFullYear()} Sophia Pompéry. All Rights Reserved.
          </small>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
